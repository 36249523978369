import React, { Component } from 'react'
import { Container, Row, Col, Alert } from 'react-bootstrap'

import BookJacket from '../../../../content/images/book-jacket.jpg'

class Book extends Component {
  render() {
    return (
      <section>
        <Container>
          <Row>
            {/* <Col xs={12} md={12}>
              <Alert variant="info" style={{ textAlign: 'center' }}>
                <h2>በኮቪድ19 ወቅት ገጸ በረከት ለኢትዮጵያውያን፦</h2>
                መደመር መጽሐፍ በድምጽ ተዘጋጅቶ፣ በነጻ ለአንባቢያን/አድማጮች ረቡዕ ግንቦት 12፣ 2012
              </Alert>

              <p>&nbsp;</p>
            </Col> */}

            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
              <div className="d-none d-md-block">
                <h2>
                  አሁን በድምፅ ትረካ የተዘጋጀውን #መደመር መጽሐፍ በነጻ ለማድመጥ ወይም ፋይሉን ለማውረድ ይችላሉ።
                  ቢሻዎት ደግሞ፣ የመጽሐፉን ምዕራፎች ዕለታዊ ምንባብ በፋና ሬዲዮ እና በኢትዮጵያ ሬዲዮ ያድምጡ።
                </h2>
              </div>
              <p>&nbsp;</p>
              <p>
                መደመር የተሰኘው ይህ መጽሐፍ የኖቤል የሰላም ሽልማት ተሸላሚና የወቅቱ የኢትዮጵያ ጠቅላይ ሚኒስትር
                ዶክተር ዐቢይ አሕመድ የሃገር መምራት ሃላፊነት ላይ ከመጡ በኋላ የጻፉት የመጀመሪያ መጽሐፋቸው ሲሆን፤
                በይዘቱም ወቅታዊው የኢትዮጵያ የፖለቲካ መህዳር ሃገር በቀልና አዲስ የፖለቲካ አስተምህሮን ለማስተዋወቅ
                ታሪካዊ፣ ተግባራዊና አመክኔያዊ ገፊ ምክንያቶች ኢንደሚታዩበት ይሞግታል። እንደ መጽሐፉ የአዲስ ሃገር
                በቀል ፖለቲካዊ ርዕዮተአለም አስፈላጊነት የሚመነጨው ባለፈው ግማሽ ምዕተ-አመት በሃገራችን ወስጥ
                ከሌላው አለም እየተዋስን የሞከርናቸው ፖለቲካዊ አስተምህሮዎች የተባለላቸውን ያህል ውጤት ያላመጡበት
                አንዱ ምክንያት ለኢትዮጵያ ባህላዊና ታሪካዊ እሴቶች እንዲሁም ፖለቲካዊ ምህዳር ባዕድ መሆናቸው
                እንደሆነ ያስገነዝባል። በመሆኑም ይህን በሃገር ደረጃ የሚታይ በተውሶ ርዕዮተአለም የመዋለል ሃገራዊ
                ችግር ኢትዮጵያዊ ባህሎችንና ልማዶችን ተንተርሶ ሃገራዊ ችግርን የመፍታት አስፈላጊነትን በማጤን
                መደመርን እንደ ሃገር በቀልና አዲስ የፖለቲካ ርዕዮተ-አለማዊ አቅጣጫ ያመላክታል።
              </p>
              <p>
                መጽሐፉ አራት ክፍሎች ያሉት ሲሆን በዓለም ላይ የሚቀነቀኑ የተለያዩ ፍልስፍናዎችን በመዳሰስ ለሀገር
                በሚጠቅም መልኩ ቁም ነገራቸውን በመቅሰም የራሳችን የምንለው ችግራችንን ሊፈታ የሚችል መላ ማመላከት
                ላይ እንድናተኩር ይጋብዛል። ለዚህም እንስካሁን የተለመደውን የተነጣጠሉ ትናንሽ አቅሞችን ከመገንባት
                ባለፈ በመደመር ትልቅ ሃገራዊ ወረት መፍጠርን ያበረታታል።
              </p>
              <p>&nbsp;</p>
              <p>
                <a
                  className="btn btn-primary btn-medemer"
                  href="https://amzn.to/2OfugpM"
                  role="button"
                >
                  የአማርኛ መጽሐፍ ትዕዛዝ
                </a>
                &nbsp;
                <a
                  className="btn btn-primary btn-medemer"
                  href="https://amzn.to/2qUKa0V"
                  role="button"
                >
                  Order Afaan Oromo Book
                </a>
              </p>
              <p>&nbsp;</p>
              <img src={BookJacket} className="img-responsive" width="100%" />
            </Col>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
              <div className="d-sm-block d-lg-none d-md-none">
                <h2>
                  አሁን በድምፅ ትረካ የተዘጋጀውን #መደመር መጽሐፍ በነጻ ለማድመጥ ወይም ፋይሉን ለማውረድ ይችላሉ።
                  ቢሻዎት ደግሞ፣ የመጽሐፉን ምዕራፎች ዕለታዊ ምንባብ በፋና ሬዲዮ እና በኢትዮጵያ ሬዲዮ ያድምጡ።
                </h2>
                <p>&nbsp;</p>
              </div>
              <iframe
                width="100%"
                height="450"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1098592537%3Fsecret_token%3Ds-M2x19xefk0m&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>

              <p>&nbsp;</p>

              <iframe
                width="100%"
                height="450"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1054526542&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Book
