import React, { Component } from 'react'
import { Container, Row, Col, Alert } from 'react-bootstrap'

import BookJacket from '../../../../content/images/generation-medemer-book-jacket.jpeg'

class GenerationMedemerBook extends Component {
  render() {
    return (
      <section style={{ paddingBottom: '0px' }}>
        <Container style={{ borderBottom: '1px solid #e1e1e1' }}>
          <Row>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
              <div className="d-none d-md-block">
                <h2>የመደመር ትውልድ</h2>
              </div>
              <p>&nbsp;</p>
              <p>ሀገር የምትገነባው ወይም የምትፈርሰው በትውልድ ቅብብል ነው።</p>
              <p>ከተረከበው አብልጦ የሚሠራ ትውልድ ይገነባታል። ከተረከበው አሳንሶ የሚሠራ ትውልድ ያፈርሳታል።</p>
              <p>
                ካለፈው ትውልድ በመማር፣ በዛሬው ትውልድ በመታገል፣ በነገው ትውልድ ላይ ደግሞ በመሥራት የተሻለች
                ሀገር መገንባት ይቻላል።
              </p>
              <p>ጥያቄው እንዴት? የሚለው ነው።</p>
              <p>የመደመር ትውልድ መጽሐፍ ይሄንን ለመመለስ ይሞክራል።</p>
              <p>&nbsp;</p>
              <p>
                <a
                  className="btn btn-primary btn-medemer"
                  href="https://ethio.post/product/generation-medemer/"
                  role="button"
                  target="_blank"
                >
                  መጽሐፉን ይግዙ
                </a>
              </p>
              <p>&nbsp;</p>
            </Col>

            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/TOwZI4x82mo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default GenerationMedemerBook
