import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import BackgroundImage from '../../../../content/images/tell-us.jpg'

class MedemerAction extends Component {
  render() {
    return (
      <section
        className="small medemer-action"
        style={{
          background: `linear-gradient(rgba(122, 168, 57, 0.85), rgba(122, 168, 57, 0.85)), url(${BackgroundImage}) no-repeat 0 100%`,
          backgroundSize: 'cover',
        }}
      >
        <div className="container">
          <div className="row featurette">
            <div className="col-md-6">
              <h2
                className="featurette-heading-big"
                style={{
                  color: '#fff',
                  fontWeight: 'bold',
                  lineHeight: '100%',
                  marginBottom: '0.5em',
                }}
              >
                መደመር ለእርስዎ ምን ማለት ነው?
              </h2>
              <p className="lead" style={{ color: '#fff' }}>
                ውይይቱን ይቀላቀሉ። መደመር ለእርስዎ ለእርስዎ ምን ማለት እንደሆነ ለአለም አስተያየትዎን ለማጋራት
                በይነተገናኝ ገጹን ይጠቀሙ።
              </p>

              <a
                className="btn btn-primary btn-medemer"
                href="/social"
                role="button"
              >
                ውይይቱን ይቀላቀሉ
              </a>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </section>
    )
  }
}

export default MedemerAction
